import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import NotFound404 from '../components/NotFound404';

export default () => (
  <React.Fragment>
    <SEO
      title="404 Not Found | Mindset Family Therapy"
      description="Oops. There is nothing at this page."
      pathname="/404"
      is404
    />
    <Layout>
      <NotFound404 />
    </Layout>
  </React.Fragment>
);
