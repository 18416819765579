import React from 'react';
import PageHeader from '../PageHeader';

const NotFound404 = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108930/headerBackgroundContact_lxkmce.jpg';

  return <PageHeader pageName="Page Not Found" headerImage={background} />;
};

export default NotFound404;
